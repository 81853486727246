import Axios from "@/libraries/Axios.js";
import ApiUrl from "@/libraries/ApiUrl.js";
import IsMobile from "@/libraries/IsMobile.js";
import { gtag } from "@/packages/lnw-vue-gtag/install.js";
import fbPixelBootstrap from "@/packages/lnw-vue-pixel/bootstrap.js";
import { fbq } from "@/packages/lnw-vue-pixel/install.js";
import { get_ttq } from "@/packages/lnw-vue-tiktok";
import lineTagBootstrap from "@/packages/lnw-vue-line/bootstrap.js";
import { _lt } from "@/packages/lnw-vue-line/install.js";
import {isLocalhost, mall_product_to_google_item, shop_product_to_google_item} from "@/libraries/Utilities.js";
import * as types from "./mutation-types.js";
import { gtag_dual_groups, GTAG_TARGET_GROUP_ENABLE, orderAjaxHandle } from "./helpers.js";
import { injectInvoiceFormPropertiesThenSubmit } from "@/libraries/FormInjection.js";
import store from "@/store";

const tracker = require('@/config/tracker.json');

const pc_exists_in_query_string = window.location.search.includes('&_pc=');
const bc_exists_in_query_string = window.location.search.includes('&_bc=');


Axios.defaults.timeout = 20000;


export default {
  async initOrderId({ commit, dispatch }, { order_id, web_name, secret, type, is_x }) {
    commit('setOrderKey', {
      order_id,
      web_name,
      secret,
      type
    });

    if (is_x) {
      commit('SET_IS_X', true);
    }
    return dispatch('fetchOrder');
  },
  async fetchOrder({ commit, dispatch, getters, rootState, rootGetters }, payload) {
    if (payload === null || typeof payload !== 'object')
      payload = {};

    const skipUpdateStepMetasOpen = !!payload.skipUpdateStepMetasOpen;

    const response = await Axios.get(ApiUrl.order(getters.orderApiUrlParams), { headers: getters.axios_headers });

    // ถ้าตอบ order_secret มา แสดงว่ามันเป็น order แล้ว ให้ redirect ไป order URL แทน
    if (response.data.cart?.order_id && response.data.cart?.order_secret) {
      const params = getters.orderApiUrlParams;
      window.location.href = `/order/${params.web_name}/${response.data.cart.order_id}?s=${response.data.cart.order_secret}`;
      return;
    }

    commit('setShopData', response.data.shop);

    if (response.data.mall)
      commit('setMallData', response.data.mall);

    orderAjaxHandle(response, { commit, dispatch, rootState, rootGetters });

    if (!skipUpdateStepMetasOpen) {
      dispatch('updateStepMetasOpen');
    }

  },
  // kplus ใช้ action นี้เพื่อตรวจสอบ ว่าจ่ายเงินสำเร็จรึยัง
  async waitUntilOrderStatusChange({ getters, state }) {
    return Axios.get(ApiUrl.order(getters.orderApiUrlParams), {
      headers: getters.axios_headers,
      bypass_ajax_loading: true,
    })
      .then(response => {
        if (state.orderData.order_status !== response.data.cart?.order_status) {
          // ถ้าสถานะต่างจากเดิมแล้ว ก็ให้ reload ไปเลยแล้วกัน
          window.location.reload();
        }
      });
  },
  updateStepMetasOpen({ commit, getters }) {
    // จะคำนวณเรื่องการ expand ของแต่ละ step ในกรณีไหน
    // if (!state.existingOrderOwner) {
      // ถ้าขั้นตอนไหนผ่านแล้ว  เริ่มมาจะยุบไว้ก่อน  แล้วแสดงติ๊กสีเขียว
      let opens = {};
      let opened_yet = false;
      for (let stepName in getters.stepMapping) {
        let stepNumber = getters.stepMapping[stepName];
        let getterKey = `isPassStep${stepNumber}`;
        let canEditGetterKey = `canEditStep${stepNumber}`;

        if (opened_yet) // ถ้ามีอะไรเปิดแล้ว ที่เหลือก็ปิดไปเลย
          opens[stepNumber] = false;
        else {
          opens[stepNumber] =
            !getters[getterKey] // ยังไม่ผ่าน
            && getters[canEditGetterKey]; // สามารถแก้ไขได้
        }

        if (opens[stepNumber])
          opened_yet = true;

        // console.log(opened_yet);
      }
      commit('setStepMetasOpen', opens);
    // }
  },
  // ประกาศกร้าวว่า ฉันคือเจ้าของ order นี้
  async claimOrder({ commit, getters, rootState, dispatch, rootGetters }) {
    return Axios.put(
      ApiUrl.order(getters.orderApiUrlParams),
      { action: 'setUser', },
      { headers: getters.axios_headers }
    )
      .then(response => orderAjaxHandle(response, { commit, rootState, dispatch, rootGetters }));
  },
  // ถอนตัวออกจาก order
  async unclaimedOrder({ commit, getters, rootState }) {
    return Axios.put(
      ApiUrl.order(getters.orderApiUrlParams),
      { action: 'unsetUser', },
      { headers: getters.axios_headers }
    )
      .then(response => {
          orderAjaxHandle(response, {commit, rootState});
          if(getters.isRequiredLogin){
            commit('setForm', {key: 'buyerInfoConfirmed', value: false});
          }
        }
      );
  },
  async saveBuyerInfo({ commit, getters, rootState }, payload) {
    commit('setForm', { ajaxSaveBuyerInfoRunning: true });
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'saveBuyerInfo',
      contact_email: payload.email,
      contact_mobile: payload.mobileNumber,
      receive_sms_flag: payload.smsCheck ? 1 : 0,
      allow_ads_flag: payload.adsCheck ? 1 : 0,
      allow_line_notify_flag: payload.lineNotifyCheck ? 1 : 0,
      messenger_optin_user_ref: payload.messengerOptInUserRef,
    }, { headers: getters.axios_headers })
      .then((response) => {
        // console.log(response);
        orderAjaxHandle(response, { commit, rootState });
      })
      .finally(() => commit('setForm', { ajaxSaveBuyerInfoRunning: false }));
  },
  async saveConsentFlag({ commit, getters, rootState }, payload) {
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'setAllowAdsFlag',
      allow_ads_flag: payload.adsCheck ? 1 : 0,
    }, { headers: getters.axios_headers })
      .then((response) => {
        orderAjaxHandle(response, { commit, rootState });
      });
  },

  async saveShippingMethod({ commit, getters, rootState, dispatch }, payload) {
    dispatch('tracker_addShippingInfo');
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'saveShippingMethod',
      shipping_type_id: payload.shipping_type_id,
    }, { headers: getters.axios_headers })
      .then((response) => orderAjaxHandle(response, { commit, rootState }));
  },
  async saveOrderMeta({ commit, getters, rootState }, payload) {
    payload.action = 'saveOrderMeta';
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams),
      payload,
      { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }))
  },
  async updateOrderDetail({ dispatch }, detail) {
    return dispatch('saveOrderMeta', { detail });
  },
  async savePaymentMethodAndCheckout({ state, commit, getters, rootState, rootGetters, dispatch }, payload) {
    const isCart = getters.isCart
    const redirectToPayPaymentProviders = ['paypal', 'pay@all', 'payatall']

    const return_data = {
      redirecting: false,
      process_2c2p: false,
      delaying: 1000, // หน่วงเวลา เพื่อให้ tracker ยิงเก็บ event กันเสร็จก่อน
      fallback_order_UUID: null, // กรณีที่กรอกบัตรเครดิต ตั้งแต่ยังเป็น Cart เราจะยังไม่มี Order UUID ซึ่งจำเป็นต้องระบุ เวลาจะยิงไปจ่ายเงินด้วยบัตรเครดิต
      latest_lnwpay_us: null,
    };

    const actual_payload = {
      action: 'savePaymentMethodAndCheckout',
      payment_method: payload.payment_method,
      payment_provider: payload.payment_provider,
      confirm_flag: 1,
    };

    if (typeof payload.receipt_id !== 'undefined')
      actual_payload.receipt_id = payload.receipt_id;

    dispatch('tracker_addPaymentInfo');

    try {
      const previous_order_status = getters.order?.order_status;

      // savePaymentMethodAndCheckout
      const response = await Axios.put(ApiUrl.order(getters.orderApiUrlParams), actual_payload, { headers: getters.axios_headers });
      return_data.fallback_order_UUID = response.data.cart?.order_uuid;
      return_data.latest_lnwpay_us = response.data.cart?.lnwpay_us;
      await orderAjaxHandle(response, { commit, rootState });

      if (previous_order_status === 'wait_order') {
        // กรณีที่ยิง event:purchase เวลา checkout จะยิงตรงนี้
        if (tracker.send_purchase_on_checkout) {
          dispatch("tracker_purchase");
        }
        // ยิง track placeAnOrder
        dispatch("tracker_placeAnOrder");
      }

      // ตอนยังเป็น Cart อยู่ถ้าเลือก payment_provider ที่ต้อง redirect ไปจ่าย ให้ดักตรงนี้
      if (isCart && redirectToPayPaymentProviders.includes(actual_payload.payment_provider)) {
        if (response.data.response?.invoiceFormProperties) {
          injectInvoiceFormPropertiesThenSubmit(response.data.response.invoiceFormProperties)
          return_data.redirecting = true
        }
      }
      else if (getters.isPayable) {
        if (getters.isLnwPayPaymemt) {
          const web_id = getters.shop.web_id;
          let redirect_url = false;
          if (getters.order.payment_provider === 'credit_card') {
            if (window.My2c2p) { // ถ้า load library 2c2p สำเร็จ
              return_data.process_2c2p = true;
            } else { // ถ้าไม่สำเร็จ redirect ไป lnwpay legacy
              redirect_url = `https://www.lnwpay.com/pay?provider=credit_card&sid=${web_id}`;
            }
          }
          // ถ้าเป็นโอนเงิน ไม่ต้อง redirect ไปที่ LnwPay เพราะว่าแจ้งโอนเงินผ่าน Lnw.Me ได้เลย
          // ถ้าเป็นโอนเงิน ไม่ต้อง redirect ไปที่ LnwPay เพราะว่าแจ้งโอนเงินผ่าน Lnw.Me ได้เลย
          else if (getters.isBankTransferPaymentProvider) {
            redirect_url = false;
          } else {
            redirect_url = `https://www.lnwpay.com/pay/quick/${getters.order.payment_provider}?sid=${web_id}`;
          }

          if (redirect_url) {
            redirect_url += `&oid=${getters.order.order_id}` +
              `&amount=${getters.order.price.pay}` +
              `&s=${getters.lnwshop_s}`;

            // ถ้าเป็นร้าน test ให้วิ่งไป testpay แทน
            if (getters.isShopIsTestShop) {
              redirect_url = redirect_url.replace('www.lnwpay.com', 'testpay.lnw.co.th');
            }

            // kplus ส่งเบอร์โทรไปด้วย
            if (getters.order.payment_provider === 'kplus') {
              redirect_url += '&mobile=' + state.form.kPlusMobileNumber;
            }

            // รอ @ToN ทำ API ให้ก่อน แล้วค่อย uncomment code ก้อนนี้
            // if (getters.order.payment_provider === 'kplus') {
            //     await dispatch('lnwpay/payByKplus', {
            //         sid: web_id,
            //         oid: getters.order.order_id,
            //         amount: getters.order.price.pay,
            //         us: getters.lnwpay_us,
            //         mobile: state.form.kPlusMobileNumber,
            //     }, {root: true});
            //     await dispatch('kplusActivateDialog', redirect_url);
            // }
            // else
            return_data.redirecting = true;

            // ต้องส่ง GET['us'] ไปด้วย เพื่อให้ lnwpay ทำงานได้ทันที
            if (getters.order.lnwpay_us) {
              redirect_url += `&us=${getters.lnwpay_us}`;
            } else {
              if (rootGetters['user/isLogin']) { // ถ้า login
                return_data.delaying = 4500; // หน่วงเวลาให้ LnwPay ได้ order ของสมาชิกก่อน
              }
            }

            setTimeout(function () {
              window.location.href = redirect_url;
            }, return_data.delaying);
            // }
          }
        }
        else if (!isCart && redirectToPayPaymentProviders.includes(getters.order.payment_provider)) {
          const invoiceFormProperties = await dispatch('getInvoiceFormProperties', getters.order.payment_provider);
          injectInvoiceFormPropertiesThenSubmit(invoiceFormProperties)
          return_data.redirecting = true
        }
      }
    } catch (e) {
      //
      alert(e);
    }

    return return_data;
  },
  async confirmCheckout({commit, getters, rootState, dispatch }, payload) {
    const actual_payload = {
      action: 'confirmCheckout',
      confirm_flag: 1,
    };

    if (typeof payload.receipt_id !== 'undefined')
      actual_payload.receipt_id = payload.receipt_id;

    try {
      const previous_order_status = getters.order?.order_status;

      const response = await Axios.put(ApiUrl.order(getters.orderApiUrlParams), actual_payload, { headers: getters.axios_headers });
      await orderAjaxHandle(response, { commit, rootState });

      if (previous_order_status === 'wait_order') {
        // กรณีที่ยิง event:purchase เวลา checkout จะยิงตรงนี้
        if (tracker.send_purchase_on_checkout) {
          dispatch("tracker_purchase");
        }
        // ยิง track placeAnOrder
        dispatch("tracker_placeAnOrder");
      }
    } catch (e) {
      //
    }
    return true;
  },

  async orderBuyAgain({ dispatch }, { order_id }) {
    const response = await dispatch('_callShopApiAction', { payload: { action: 'orderBuyAgain', order_id } });
    if(!response.data.error && response.data.response){
      window.location.href = response.data.response;
    }
  },
  async cancelInformPayment({ commit, getters, rootState }, { payment_id }) {
    const payload = {
      action: 'cancelInformPayment',
      payment_id,
    };

    return Axios.put(
      ApiUrl.order(getters.orderApiUrlParams),
      payload,
      { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async saveInformPayment({ commit, dispatch, getters, rootState }, payload) {
    const payment_inform = {
      provider: payload.provider,
      amount: payload.amount,
      payment_date: payload.payment_date,
      payment_time: payload.payment_time,
      account_id: payload.account_id,
      name: payload.name,
      email: payload.email,
      mobile: payload.mobile,
      detail: payload.detail,
      is_lnwpay: payload.is_lnwpay,
    };

    if (Array.isArray(payload.files) && payload.files.length)
      payment_inform.file = payload.files[0];
    else if (payload.file)
      payment_inform.file = payload.file;

    if ((Array.isArray(payload.order_ids) && payload.order_ids.length) || payload.order_ids)
      payment_inform.order_ids = payload.order_ids;

    let return_data = {
      error: false,
    };
    if(payload.directInform == true){
      await dispatch('_callShopApiAction', { payload:
            { action: 'saveInformPayment',
              payment_inform
            }
      }).then(async response => {
        if(response.data.error){
          return_data.error = true;
        }
      });
    }else{
      await Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
        action: 'saveInformPayment',
        payment_inform,
      }, {headers: getters.axios_headers})
          .then(async response => {
            orderAjaxHandle(response, {commit, rootState, dispatch});
            if(response.data.error){
              return_data.error = true;
            }else{
              await dispatch('setInformDialog', false)
            }
          });
    }
    return return_data;
  },
  async setCouponCode({ commit, getters, rootState }, { coupon_code, coupon_id }) {
    if (!getters.canAddCoupon)
      return false;

    const payload = {};
    if (coupon_id) {
      payload.action = 'setCouponId';
      payload.coupon_id = coupon_id;
    } else if (coupon_code) {
      payload.action = 'setCouponCode';
      payload.coupon_code = coupon_code;
    }

    return Axios.put(ApiUrl.order(getters.orderApiUrlParams),
      payload,
      { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async removeCouponCode({ commit, getters, rootState }, { coupon_code, coupon_id }) {
    if (!getters.canRemoveCoupon)
      return false;

    const payload = {};
    if (coupon_id) {
      payload.action = 'removeCouponId';
      payload.coupon_id = coupon_id;
    } else if (coupon_code) {
      payload.action = 'removeCouponCode';
      payload.coupon_code = coupon_code;
    }

    return Axios.put(ApiUrl.order(getters.orderApiUrlParams),
      payload,
      { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async loadPromotionSubItems({ commit, getters, rootState }, payload) {
    if(!payload.promotion_id){
      return false;
    }
    const response = await Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'loadPromotionSubItems',
      promotion_id: payload.promotion_id
    }, { headers: getters.axios_headers });

    orderAjaxHandle(response, { commit, rootState });
    return response.data?.response?.products;
  },
  async changeItemQuantity({ state, commit, getters, rootState, dispatch }, payload) {

    if (Array.isArray(state.orderData.items)) {
      for (const item of state.orderData.items) {
        if (item.spec.type == 'product' && payload.iid.toString() === item.iid.toString()) {
          // ส่ง product ที่ทับ quantity ให้เป็นส่วนต่างจำนวนของสินค้า ที่เข้า-ออก ตะกร้า แทน
          if (payload.quantity > item.quantity) {
            // Add to Cart
            const quantity = payload.quantity - item.quantity;
            dispatch('tracker_addToCart', { items: [Object.assign(item, { quantity })] });
          } else if (payload.quantity < item.quantity) {
            // Remove from Cart
            const quantity = item.quantity - payload.quantity;
            dispatch('tracker_removeFromCart', { items: [Object.assign(item, { quantity })] });
          }
        }
      }
    }
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'changeProductQuantity',
      items: [
        {
          iid: payload.iid,
          quantity: payload.quantity,
        },
      ],
    }, { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async changeProductQuantity({ state, commit, getters, rootState, dispatch }, payload) {

    if (Array.isArray(state.orderData.items)) {
      let _add_items = [];
      let _remove_items = [];
      for (const item of state.orderData.items) {
        if (item.spec.type == 'product') {

          let _payload = null;
          if(Array.isArray(payload.items)){
            _payload = payload.items.find(({ iid }) => iid.toString() === item.iid.toString());
          }else{
            _payload = payload;
          }

          if(_payload && _payload.iid.toString() === item.iid.toString()){
            // ส่ง product ที่ทับ quantity ให้เป็นส่วนต่างจำนวนของสินค้า ที่เข้า-ออก ตะกร้า แทน
            if(_payload.quantity > item.quantity){
              // Add to Cart
              const quantity = _payload.quantity - item.quantity;
              _add_items.push([Object.assign(item, {quantity})]);
            }else if(_payload.quantity < item.quantity){
              // Remove from Cart
              const quantity = item.quantity - _payload.quantity;
              _remove_items.push([Object.assign(item, {quantity})]);

            }
          }
        }
      }
      if(_add_items.length){
        dispatch('tracker_addToCart', {items: _add_items});
      }
      if(_remove_items.length){
        dispatch('tracker_removeFromCart', {items: _remove_items});
      }
    }
    let items = [];
    if(Array.isArray(payload.items)){
      payload.items.forEach((item)=> {
        items.push({
          iid: item.iid,
          quantity: item.quantity,
        })
      });
    }else{
      items = [
        {
          iid: payload.iid,
          quantity: payload.quantity,
        },
      ];
    }
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'changeProductQuantity',
      items: items
    }, { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async setSubItemData({ commit, getters, rootState }, payload) {
    let items = [];
    if(Array.isArray(payload.items)){
      payload.items.forEach((item)=> {
        items.push({
          product_id: item.product_id,
          q: item.q,
          d: item.d?item.d:null
        })
      });
    }else{
      items = [
        {
          product_id: payload.product_id,
          q: payload.q,
        },
      ];
    }
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'setSubItems',
      iid: payload.iid.toString(),
      promotion_id: payload.promotion_id,
      max_quantity: payload.max_quantity,
      items: items
    }, { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async addProductToCart({ commit, getters, rootState }, payload) {
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'addProductQuantity',
      product_id : payload.id,
    }, { headers: getters.axios_headers })
        .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async removeProductFromCart({ dispatch }, payload) {
    return dispatch('changeProductQuantity', {
      items: [
        {
          iid: payload.iid,
          quantity: 0
        },
      ]
    });
  },
  async removeProductsFromCart({ dispatch }, payload) {
    let items = [];
    payload.iids.forEach((iid) => {
      items.push({
        iid: iid,
        quantity: 0
      });
    });


    return dispatch('changeProductQuantity', {
      items: items
    });
  },
  async updateProductRemark({ commit, getters, rootState }, payload) {
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'updateProductRemark',
      items: [
        {
          iid: payload.iid,
          detail: payload.remark,
        },
      ],
    }, { headers: getters.axios_headers })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async updateOrderReceiver({ commit, getters, rootState }, payload) {
    const _payload = {};
    _payload.action = 'updateOrderReceiver';
    if (payload.hasOwnProperty('receiver'))
      _payload.receiver = payload.receiver;
    if (payload.hasOwnProperty('receiver_id'))
      _payload.receiver_id = payload.receiver_id;
    if (payload.hasOwnProperty('contact_key'))
      _payload.contact_key = payload.contact_key;
    if (payload.hasOwnProperty('unset_contact'))
      _payload.unset_contact = payload.unset_contact;

    return Axios.put(ApiUrl.order(getters.orderApiUrlParams),
      _payload,
      {
        headers: getters.axios_headers,
      })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async updateOrderSender({ commit, getters, rootState }, payload) {
    const _payload = {};
    _payload.action = 'updateOrderSender';
    if (payload.hasOwnProperty('sender'))
      _payload.sender = payload.sender;
    if (payload.hasOwnProperty('sender_id'))
      _payload.sender_id = payload.sender_id;

    return Axios.put(ApiUrl.order(getters.orderApiUrlParams),
      _payload,
      {
        headers: getters.axios_headers,
      })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async updateOrderSkipReceiver({ commit, getters, rootState }, payload) {
    if (getters.isRequiredReceiver)
      return false;

    payload.action = 'updateOrderSkipReceiver';

    return Axios.put(
        ApiUrl.order(getters.orderApiUrlParams),
        payload,
        { headers: getters.axios_headers }
    )
        .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async updateOrderLangAndCurrency({ commit, getters, rootState }, payload) {
    payload.action = 'setLangAndCurrencyKey';

    return Axios.put(
        ApiUrl.order(getters.orderApiUrlParams),
        payload,
        { headers: getters.axios_headers }
    )
        .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async cancelOrder({ commit, getters, rootState }) {
    if (!getters.canCancelOrder)
      return false;

    return Axios.put(
      ApiUrl.order(getters.orderApiUrlParams),
      { action: 'cancelOrder' },
      { headers: getters.axios_headers }
    )
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  async updateOrderReceipt({ getters, commit, rootState }, payload) {
    if (!getters.canEditReceiptContact)
      return false;

    payload.action = 'updateOrderReceipt';

    return Axios.put(
      ApiUrl.order(getters.orderApiUrlParams),
      payload,
      { headers: getters.axios_headers }
    )
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  // ยิงเพื่อระบุว่าจะใช้ lnwPoint เท่าไหร่
  async setRedeemLnwPoint({ commit, getters, rootState }, { lnwpoint_amount }) {
    const _payload = {
      action: 'setRedeemLnwPoint',
      lnwpoint: lnwpoint_amount,
    };
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams),
      _payload,
      {
        headers: getters.axios_headers,
      })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  // ยิงเพื่อระบุว่าจะใช้ Point ของร้าน เท่าไหร่
  async setRedeemShopPoint({ commit, getters, rootState }, { shop_point_amount }) {
    const _payload = {
      action: 'setRedeemShopPoint',
      shop_point: shop_point_amount,
    };
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams),
      _payload,
      {
        headers: getters.axios_headers,
      })
      .then(response => orderAjaxHandle(response, { commit, rootState }));
  },
  // สำหรับยิงเพื่อเอาข้อมูลชำระเงินช่องทาง Online ของร้านค้า LnwShop (PayPal, Pay@All)
  async getInvoiceFormProperties({ getters }, provider) {
    const payload = {
      action: 'getInvoiceFormProperties',
      provider
    };

    const response = await Axios.put(
      ApiUrl.order(getters.orderApiUrlParams),
      payload,
      { headers: getters.axios_headers }
    )
    return response.data?.response?.invoiceFormProperties;
  },
  previousStep({ state, commit, getters }, payload) {
    const currentStep = getters.stepMapping[payload.currentStep];
    for (let stepNumber in state.stepMetas) {
      if (state.stepMetas.hasOwnProperty(stepNumber)) {
        // หุบอันนี้และอันถัดไป และ เปิดอันก่อนหน้า
        if (parseInt(stepNumber) === currentStep - 1) {
          commit('setStepMetaKeyValue', { stepNumber: stepNumber, key: 'open', value: true });
        } else {
          commit('setStepMetaKeyValue', { stepNumber: stepNumber, key: 'open', value: false });
        }
      }
    }
  },
  rewindStep({ state, commit, getters }, payload) {
    const currentStep = getters.stepMapping[payload.currentStep];
    for (let stepNumber in state.stepMetas) {
      if (state.stepMetas.hasOwnProperty(stepNumber)) {
        // หุบอันนี้และอันถัดไป และ เปิดอันก่อนหน้า
        if (parseInt(stepNumber) === currentStep - 2) {
          commit('setStepMetaKeyValue', { stepNumber: stepNumber, key: 'open', value: true });
        } else {
          commit('setStepMetaKeyValue', { stepNumber: stepNumber, key: 'open', value: false });
        }
      }
    }
  },
  async nextStep({ state, commit, getters }, payload) {
    let open_this_step = false;
    const currentStep = getters.stepMapping[payload.currentStep];
    //console.log(currentStep);
    for (let step in state.stepMetas) {
      if (state.stepMetas.hasOwnProperty(step)) {
        // หุบอันก่อนหน้า และ ตัวเอง
        if (parseInt(step) <= currentStep) {
          commit('setStepMetaKeyValue', { stepNumber: step, key: 'open', value: false });
        }
        // step ต่อไปถ้ายังไม่ Pass ให้ขยายออกมา
        else {
          let getterKey = `isPassStep${step}`;
          if (!getters[getterKey]) {
            open_this_step = step;
            break;
          }
        }
      }
    }
    // console.log(currentStep);
    // console.log(open_this_step);
    // ถ้าเข้า if นี้ได้ แสดงว่ามันยังไม่เจอขั้นตอนไหน ที่จะขยายออกมา เพราะ !Pass (ผ่านหมดแล้ว)
    if (!open_this_step) {
      for (let step in state.stepMetas) {
        if (parseInt(step) === currentStep)
          continue;
        if (!getters[`isPassStep${step}`]) {
          open_this_step = step;
        }
      }
    }
    // เจอ step ที่ต้องเปิดออกมา
    if (open_this_step) {
      commit('setStepMetaKeyValue', { stepNumber: open_this_step, key: 'open', value: true });

    }else if(state.orderData.order_status == 'wait_order'){
      //เข้ากรณีนี้ได้ เพราะจะมี order ที่สร้างมาจากหลังร้านแล้ว shipping หรือ payment นั้น complete มาแล้ว
      // จริงๆ จะมีกรณีเดียวคือ พอใส่ shipping (currentStep) แล้วต้องเปิด payment (ที่ step complete แล้ว | is_cod)
      // ไม่มีกรณีที่ shipping และ payment ทั้งคู่ complete <-- เนื่องจาก order จะไปอยู่ status != wait_order แล้ว
      // ไม่มีกรณีที่ shipping complete แต่ payment ไม่ complete เพราะต้องถูกวนเข้าตั้งแต่ loop บนก่อนหน้านี้แล้ว
      open_this_step = (currentStep==1)?2:3;
      commit('setStepMetaKeyValue', { stepNumber: open_this_step, key: 'open', value: true });
    }

    // scrollTo open_this_step
    if(payload.vm) {
      setTimeout(() => {
        const isMobile = IsMobile();
        let offsetHeight = (document.getElementById('infoFixedTop').offsetHeight === 0 && !isMobile) ? 70 : document.getElementById('infoFixedTop').offsetHeight;
        let offsetHeightBanner = 0;
        if (document.getElementById('bannerStatus')) {
          offsetHeightBanner = document.getElementById('bannerStatus').offsetHeight;
        }
        let offsetTotal = (!isMobile) ? offsetHeightBanner : offsetHeight + offsetHeightBanner;
        if (open_this_step) {
          payload.vm.$vuetify.goTo(`#step${open_this_step}`, {offset: offsetTotal});
        } else {
          window.scrollTo(0,0);
        }
      }, 200);
    }
  },
  async _callShopApiAction({ getters }, { payload }) {
    return Axios.put(
      ApiUrl.lnwshop(getters.shopApiUrlParams, 'action'),
      payload,
      { headers: getters.axios_headers }
    );
  },
  async joinMember({ dispatch }) {
    await dispatch('_callShopApiAction', { payload: { action: 'joinMember' } });
  },
  async leaveMember({ dispatch }) {
    await dispatch('_callShopApiAction', { payload: { action: 'leaveMember' } });
  },
  async contactSplit({ dispatch }, payload) {
    return await dispatch('_callShopApiAction', { payload: { action: 'contactSplit' , payload} });
  },
  forceAsGuest({ commit }, payload) {
    commit('setForm', {
      key: 'forceAsGuest',
      value: (typeof payload === 'object' && payload.hasOwnProperty('flag')) ? payload.flag : true
    });
  },
  backToEditBuyerInfo({ commit }) {
    commit('setForm', {
      buyerInfoConfirmed: false,
      buyerMixInfoConfirmed: false,
    });
  },
  expandStep({ commit, getters }, payload) {
    let stepNumber = getters.stepMapping[payload.stepName];
    if (payload.close_another) {
      let opens = {};
      for (let i = 1; i <= 3; i++)
        opens[i] = false;
      opens[stepNumber] = true;
      commit('setStepMetasOpen', opens);
    } else {
      commit('setStepMetaKeyValue', { stepNumber, key: 'open', value: true });
    }
  },
  shrinkStep({ commit, getters }, payload) {
    let stepNumber = getters.stepMapping[payload.stepName];
    commit('setStepMetaKeyValue', {
      stepNumber,
      key: 'open',
      value: false,
    });
  },
  setIsShowOverlay({ commit }, flag) {
    commit(types.SET_IS_SHOW_OVERLAY, flag);
  },
  setIsShowOverlayPayment({ commit }, flag) {
    commit(types.SET_IS_SHOW_OVERLAY_PAYMENT, flag);
  },
  setIsAjaxLoading({ commit }, flag) {
    commit(types.SET_IS_AJAX_LOADING, flag);
  },
  setIsAjaxResponse({ commit }, flag) {
    commit(types.SET_IS_AJAX_RESPONSE, flag);
  },
  setIsAjaxResponseMessage({ commit }, msg) {
    commit(types.SET_IS_AJAX_RESPONSE_MESSAGE, msg);
  },
  setIsCopied({ commit }, flag) {
    commit(types.SET_IS_COPIED, flag);
  },
  setIsLoginRequired({ commit }, flag) {
    commit(types.SET_IS_LOGIN_REQUIRED, flag);
  },
  setEnableSetIsAjaxResponse({ commit }, flag) {
    commit(types.SET_ENABLE_SET_IS_AJAX_RESPONSE, flag);
  },
  shippingMethodCloseAddressEditorForm({ state, commit }, forceClear = false) {
    // ถ้าอยู่ในโหมดเพิ่มที่อยู่ใหม่  ถ้ากดย้อนกลับ จะถือว่ายังไม่เลือกตัวเลือกไหนเลย
    const payload = {};
    if (forceClear && state.form.editAddressId === -1) {
      payload.shippingMethodShippingAddressId = 0;
    }
    payload.editAddressId = 0;
    commit('setForm', payload);
  },
  setInformDialog({ commit }, flag) {
    commit(types.SET_INFORM_DIALOG, flag);
  },
  kplusActivateDialog({ commit }, paymentUrl) {
    commit(types.SET_KPLUS_DIALOG, {
      flag: true,
      url: paymentUrl,
    });
  },
  kplusDeactivateDialog({ commit }) {
    commit(types.SET_KPLUS_DIALOG, {
      flag: false,
      url: 'about:blank',
    });
  },

  //
  // Conversions Tracking
  //
  async setupConversionTrackers({ getters, rootGetters }){
    // เนื่องจาก gtag เรามี measurement id กลางของบริษัทฝังอยู่ด้วย  ตัว gtag bootstrap ถูกรันตั้งแต่ตอน src/main.js ที่เรียก Vue.use(VueGtag
    // ส่วน platform อื่นๆ เราไม่ได้สั่งโหลดไว้ก่อน เลยต้องมาเรียกให้ bootstrap ทำงานกันใน function นี้

    /**
     * ---- Google ----
     *
     * เราจะ config แบ่งไว้เป็น 2 group
     * 1. shop
     * 2. mall
     *
     * ที่ต้องแยกเพราะเวลายิง category เราจะยิงข้อมูลคนละอย่าง
     * - shop : ยิง หมวดหมู่ ของร้านค้า
     * - mall : ยิง หมวดหมู่ ของ mall กลาง
     *
     */
        // # Shop
    const options_for_shop = {};

    if(GTAG_TARGET_GROUP_ENABLE){
      /** @see https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data#groups-and-properties */
      options_for_shop.groups = 'shop';
    }

    let referrer_domain = null;
    if(document && document.referrer){
      const split_referrer = document.referrer.split('/');
      if(Array.isArray(split_referrer) && split_referrer[2]){
        referrer_domain = split_referrer[2];
      }
    }

    if(getters.shop_gtag_linker_domains.length > 0){
      options_for_shop.linker = {domains: getters.shop_gtag_linker_domains};

      // ถ้าโดเมนต้นทาง เป็นโดเมนใน linker domains ให้กำหนด option ignore_referrer := true
      if(referrer_domain){
        for(const domain of getters.shop_gtag_linker_domains) {
          if(domain.toLowerCase().endsWith(referrer_domain)){
            options_for_shop.ignore_referrer = true;
            break;
          }
        }
      }
    }

    if(typeof window.webpackHotUpdate !== 'undefined'){
      options_for_shop.debug_mode = true;
    }
    var PDPA_enable = true;
    // PDPA
    if(PDPA_enable){
      gtag('consent', 'default', {
        ad_storage: getters.PDPA_consent_ad_storage ? 'granted' : 'denied',
        analytics_storage: getters.PDPA_consent_analytics_storage ? 'granted' : 'denied',
        wait_for_update: '1000'
      });
    }


    let urlParams = new URLSearchParams(window.location.search);
    let clientID = urlParams.get('clientId');
    let sessionID = urlParams.get('sessionId');

    // console.log('sessionID', sessionID);
    if(clientID) {
      options_for_shop['client_id'] = clientID;
    }
    if(sessionID) {
      options_for_shop['session_id'] = sessionID;
    }
    for (const gtag_id of getters.shop_gtag_ids) {
      // console.log(options_for_shop);
      gtag('config', gtag_id, options_for_shop);
    }

    // # Mall
    if (getters.mall_gtag_ids.length > 0) {
      let options_for_mall = {};

      if (GTAG_TARGET_GROUP_ENABLE) {
        /** @see https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data#groups-and-properties */
        options_for_mall.groups = 'mall';
      }
      if (getters.mall_gtag_linker_domains.length > 0) {
        options_for_mall.linker = { domains: getters.mall_gtag_linker_domains };

        // ถ้าโดเมนต้นทาง เป็นโดเมนใน linker domains ให้กำหนด option ignore_referrer := true
        if (referrer_domain) {
          for (const domain of getters.mall_gtag_linker_domains) {
            if (domain.toLowerCase().endsWith(referrer_domain)) {
              options_for_mall.ignore_referrer = true;
              break;
            }
          }
        }
      }

      for (const gtag_id of getters.mall_gtag_ids) {
        gtag('config', gtag_id, options_for_mall);
      }
    }

    // write gtag client_id to cookie (สำหรับ backend ไว้เก็บลง orders_sources เพื่อทำ server 2 server conversions tracking)
    if (getters.any_GA_MEASUREMENT_ID_that_can_get_client_id) {
      gtag('get', getters.any_GA_MEASUREMENT_ID_that_can_get_client_id, 'client_id', (client_id) => {
        document.cookie = "_g_client_id=" + client_id + "; path=/;";
      });
      gtag('get', getters.any_GA_MEASUREMENT_ID_that_can_get_client_id, 'session_id', (session_id) => {
        document.cookie = "_g_session_id=" + session_id + "; path=/;";
      });
    }

    //
    // Facebook
    //
    const pixels = getters.both_facebook_pixel_ids; // Shop & Mall

    if (pixels.length > 0) {
      const visitor_data = {};
      if (rootGetters['user/loggedInUserId']) {
        visitor_data.external_id = rootGetters['user/loggedInUserId'];
      }
      if(typeof fbPixelBootstrap != 'undefined'){
        fbPixelBootstrap()
            .then(() => {

              // PDPA
              if (PDPA_enable && getters.PDPA_consent_both) {
                fbq('consent', 'grant');
              } else {
                fbq('consent', 'revoke');
              }

              for (const id of pixels) {
                fbq('init', id, visitor_data);
              }
              fbq('track', 'PageView');
            });
      }
    }

    //
    // Google Tag Manager (GTM)
    //
    if (getters.shop?.gtm_ids && Array.isArray(getters.shop?.gtm_ids)) {
      for (const GTM_ID of getters.shop.gtm_ids) {
        // https://developers.google.com/tag-manager/devguide#multiple-containers
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start':
              new Date().getTime(), event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', GTM_ID);
      }
    }

    // Line Tag
    if (!PDPA_enable || getters.PDPA_consent_both) {
      if (getters.shop_line_tag_ids.length) {
        lineTagBootstrap()
          .then(() => {
            for (const id of getters.shop_line_tag_ids) {
              _lt("init", {
                customerType: "lap",
                tagId: id,
              });
            }
            _lt("send", "pv", getters.shop_line_tag_ids);
          });
      }
    }

    // TikTok Pixel
    if (!PDPA_enable || getters.PDPA_consent_both) {
      if (getters.shop_tiktok_pixel_ids.length) {
        const ttq = get_ttq();
        for (const id of getters.shop_tiktok_pixel_ids) {
          ttq.load(id);
        }
        ttq.page();
      }
    }
  },
  async tracker_addPaymentInfo({ getters }) {
    const currency = getters.signal_currency;
    const value = getters.signal_value;

    // Google
    const g_payload = {
      currency,
      value,
    };
    if (getters.signal_google_payment_type)
      g_payload.payment_type = getters.signal_google_payment_type;
    if (getters.signal_coupon)
      g_payload.coupon = getters.signal_coupon;
    gtag('event', 'add_payment_info', g_payload);

    // Facebook
    const fbq_payload = { currency, value };
    if (getters.signal_facebook_content_ids) {
      fbq_payload.content_ids = getters.signal_facebook_content_ids;
      fbq_payload.contents = getters.signal_facebook_contents;
      fbq_payload.content_type = 'product';
      if (getters.signal_facebook_content_category)
        fbq_payload.content_category = getters.signal_facebook_content_category;
    }
    fbq('track', 'AddPaymentInfo', fbq_payload);

    // TikTok
    if (getters.shop_tiktok_pixel_ids.length) {
      get_ttq().track("AddPaymentInfo");
    }
  },
  async tracker_addShippingInfo({ getters }) {
    const g_payload = {
      currency: getters.signal_currency,
      value: getters.signal_value,
    };
    if (getters.signal_coupon)
      g_payload.coupon = getters.signal_coupon;
    if (getters.signal_google_shipping_tier)
      g_payload.shipping_tier = getters.signal_google_shipping_tier;

    gtag_dual_groups('add_shipping_info', g_payload, getters);
  },
  async tracker_addToCart({ getters }, { items }) {
    const currency = 'THB';
    let value = '';

    const google_items_for_shop = [];
    const google_items_for_mall = [];

    const facebook_contents = [];
    const facebook_content_ids = [];
    let facebook_content_name;

    for (const item of items) {
      value += item.total_price;

      google_items_for_shop.push(shop_product_to_google_item(item));
      google_items_for_mall.push(mall_product_to_google_item(item));

      facebook_content_name = item.name;
      if (item.fb_product_id) {
        facebook_content_ids.push(item.fb_product_id);
        facebook_contents.push({
          id: item.fb_product_id,
          quantity: item.quantity,
        });
      }
    }

    gtag('event', 'add_to_cart', { currency, value, send_to: 'shop', items: google_items_for_shop });
    gtag('event', 'add_to_cart', { currency, value, send_to: 'mall', items: google_items_for_mall });

    const facebook_payload = {
      currency,
      value,
      content_name: facebook_content_name,
      content_type: 'product',
    };
    if (facebook_contents.length > 0)
      facebook_payload.contents = facebook_contents;
    if (facebook_content_ids.length > 0)
      facebook_payload.content_ids = facebook_content_ids;

    fbq('track', 'AddToCart', facebook_payload);


    // TikTok
    if (getters.shop_tiktok_pixel_ids.length) {
      get_ttq().track("AddToCart");
    }
  },
  async tracker_removeFromCart(_, { items }) {
    const currency = 'THB';
    let value = '';
    const google_items_for_shop = [];
    const google_items_for_mall = [];

    for (const item of items) {
      value += item.total_price;

      google_items_for_shop.push(shop_product_to_google_item(item));
      google_items_for_mall.push(mall_product_to_google_item(item));
    }

    gtag('event', 'remove_from_cart', { currency, value, send_to: 'shop', items: google_items_for_shop });
    gtag('event', 'remove_from_cart', { currency, value, send_to: 'mall', items: google_items_for_mall });
  },
  async tracker_beginCheckout({ state, getters }) {
    if (getters.isTrackBeginCheckoutByRedirect && !isLocalhost()) {
      if (!bc_exists_in_query_string) {
        await store.dispatch('order/setIsShowOverlay', true);
        window.location.href = getters.trackBeginCheckoutOnShopWebsiteURL;
      }
    }
    else {
      const currency = getters.signal_currency;
      const value = getters.signal_value;

      // Google Analytics
      const g_payload = {
        coupon: getters.signal_coupon,
        currency,
        value,
      };
      gtag_dual_groups('begin_checkout', g_payload, getters);

      // Google Ads Conversion trackers
      if (getters.shop
        && getters.shop.adwords
        && getters.shop.adwords.trackers
        && getters.shop.adwords.trackers.checkout
        && Array.isArray(getters.shop.adwords.trackers.checkout)
        && getters.shop.adwords.trackers.checkout.length > 0
      ) {
        gtag('event', 'conversion', {
          send_to: getters.shop.adwords.trackers.checkout.length === 1
            ? getters.shop.adwords.trackers.checkout[0]
            : getters.shop.adwords.trackers.checkout,
          value: value,
          currency: currency,
          // transaction_id: null,
        });
      }

      // Facebook Pixel
      const f_payload = {
        currency,
        value,
        num_items: state.orderData.items.length,
      };
      if (getters.signal_facebook_content_category)
        f_payload.content_category = getters.signal_facebook_content_category;
      if (getters.signal_facebook_content_ids)
        f_payload.content_ids = getters.signal_facebook_content_ids;
      if (getters.signal_facebook_contents)
        f_payload.contents = getters.signal_facebook_contents;

      fbq('track', 'InitiateCheckout', f_payload);


      // TikTok
      if (getters.shop_tiktok_pixel_ids.length) {
        get_ttq().track("InitiateCheckout");
      }
    }
  },
  async tracker_login(_, { method }) {
    gtag('event', 'login', { method });
  },
  async tracker_purchase({ state, getters }) {
    if (getters.isTrackingPurchaseByRedirect) {
      if (!pc_exists_in_query_string) {
        window.location.href = getters.trackPurchaseOnShopWebsiteURL;
      }
    }
    else {
      const currency = getters.signal_currency;
      const value = getters.signal_value;

      // Google

      /**** (ไม่ส่งแล้ว) ส่งฝั่ง client ด้วยเลย เดี๋ยว google จะ match "transaction_id" เอง ตอนนี้เปลี่ยน transaction_id ของ shop ให้เป็น order_id ของแต่ละร้านแล้ว (จากเดิมเป็น order_uuid แต่ไว้ใช้สำหรับ mall เหมือนเดิม) */
      /**** (UPDATED) แก้ไขให้กลับมาไม่ส่งเหมือนเดิม เพราะ google ไม่ยอมเอา event ครั้งที่ 2 ไป merge ข้อมูล เพราะคิดว่า duplicate) */
      if ( // ไม่ต้องยิงตรงนี้ ถ้ายิงด้วย backend ได้
        !state.orderData.should_skip_track_purchase ||
        !Array.isArray(state.orderData.should_skip_track_purchase)
        || !state.orderData.should_skip_track_purchase.includes("gg")
      ) {
        const g_payload = {
          currency,
          value,
          coupon: getters.signal_coupon,
          transaction_id: state.orderData.order_id,
          mall_transaction_id: `${getters.shop.web_id}.${state.orderData.order_uuid}`,
          shipping: getters.signal_shipping_cost
        };
        gtag_dual_groups("purchase", g_payload, getters);
      }

      // Facebook
      const f_payload = {
        currency,
        value,
        num_items: state.orderData.items.length
      };
      if (getters.signal_facebook_content_ids)
        f_payload.content_ids = getters.signal_facebook_content_ids;
      if (getters.signal_facebook_contents)
        f_payload.contents = getters.signal_facebook_contents;
      if (state.orderData.items.length === 1)
        f_payload.content_name = state.orderData.items[0].name;
      f_payload.content_type = "product";

      if ( // ไม่ต้องยิงตรงนี้ ถ้ายิงด้วย backend ได้
        !state.orderData.should_skip_track_purchase ||
        !Array.isArray(state.orderData.should_skip_track_purchase) ||
        !state.orderData.should_skip_track_purchase.includes("fb")
      ) {
        const thirdParam = {};
        if (state.orderData.order_id) {
          // https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events/
          thirdParam.eventID = `purchase.order.${state.orderData.order_id}`;
        }
        fbq("track", "Purchase", f_payload, thirdParam);
      }

      // Line Tag
      if (getters.shop_line_tag_ids.length) {
        _lt("send", "cv", { type: "Conversion" }, getters.shop_line_tag_ids);
      }


      // TikTok
      if (getters.shop_tiktok_pixel_ids.length) {
        get_ttq().track("CompletePayment");
      }
    }
  },
  async tracker_contact({ getters }) {
    fbq('track', 'Contact');


    // TikTok
    if (getters.shop_tiktok_pixel_ids.length) {
      get_ttq().track("Contact");
    }
  },
  async tracker_placeAnOrder({ getters }) {
    // TikTok
    if (getters.shop_tiktok_pixel_ids.length) {
      get_ttq().track("PlaceAnOrder");
    }
  },

  async try_to_track_purchase({ dispatch, getters }) {
    try {
      const response = await Axios.put(
        ApiUrl.order(getters.orderApiUrlParams),
        { action: 'grantTrackPurchase' },
        { headers: getters.axios_headers }
      );
      if (response?.data?.response?.grant) {
          dispatch('tracker_purchase');
      }
    } catch (e) {
      //
    }
  },

  // Facebook checkbox plugin
  async facebookCheckboxPluginConfirmOptIn({getters}) {
    let res = false;
    if (window.FB && window.FB.CheckboxPlugin && window.FB.CheckboxPlugin.confirm) {
      // console.log('optin !');
      // console.log('app_id', getters.facebookCheckboxPluginAppId);
      // console.log('page_id', getters.facebookCheckboxPluginPageId);
      // console.log('ref', getters.facebookCheckboxPluginRef);
      // console.log('user_ref', getters.facebookCheckboxPluginUserRef);
      try {
        window.FB.CheckboxPlugin.confirm({
          'app_id': getters.facebookCheckboxPluginAppId,
          'page_id': getters.facebookCheckboxPluginPageId,
          'ref': getters.facebookCheckboxPluginUserRef,
          'user_ref': getters.facebookCheckboxPluginUserRef,
        });
        res = 'success';
        // console.log(result);
      } catch(e){
        res = e;
        // console.log('error', e);
      }
    }
    return res;
  },

  async setMessengerOptInUserRef({ commit, getters, rootState }, payload) {
    // console.log('set user ref !');
    return Axios.put(ApiUrl.order(getters.orderApiUrlParams), {
      action: 'setMessengerOptInUserRef',
      user_ref: payload.user_ref
    }, { headers: getters.axios_headers })
        .then((response) => {
          orderAjaxHandle(response, { commit, rootState });
        });
  },
};